<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new modify -->
                <b-overlay :show="add_airdrop_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="New"
                        @ok="addNewModify"
                    >
                        <b-form>
                            <b-form-group label="UserId" label-for="userId">
                                <b-form-input id="userId" placeholder="user id" v-model="newModify.userId" />
                            </b-form-group>

                            <b-form-group label="Credit Type" label-for="creditName">
                                <v-select
                                    id="creditName"
                                    label="text"
                                    :clearable="false"
                                    :reduce="(text) => text.value"
                                    placeholder="Credit Type"
                                    v-model="newModify.creditName"
                                    :options="creditNameOptions"
                                />
                            </b-form-group>

                            <b-form-group label="Tag" label-for="tag">
                                <v-select
                                    id="tag"
                                    label="text"
                                    :clearable="false"
                                    :reduce="(text) => text.value"
                                    placeholder="Tag"
                                    v-model="newModify.tag"
                                    :options="tagOptions"
                                />
                            </b-form-group>

                            <b-form-group label="Amount" label-for="amount">
                                <b-form-input id="amount" placeholder="amount" v-model="newModify.amount" />
                            </b-form-group>

                            <b-form-group label="Log" label-for="log">
                                <b-form-input id="log" placeholder="Log" v-model="newModify.log" />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>

        <!-- table -->
        <div class="col-12">
            <b-card header="Manager Meson Reward">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button class="mr-3" variant="primary" v-b-modal.modal-create > New </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="user id" v-model="queryCondition.userId" />
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="tag"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(text) => text.value"
                                                placeholder="tag"
                                                v-model="queryCondition.tag"
                                                :options="tagOptions"
                                            />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="tag"
                                                label="text"
                                                :clearable="true"
                                                :reduce="(text) => text.value"
                                                placeholder="credit type"
                                                v-model="queryCondition.creditType"
                                                :options="creditNameOptions"
                                            />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-date-picker v-model="dateRange" :model-config="modelConfig" timezone="UTC" is-range is24hr>
                                                <template v-slot="{ inputValue, togglePopover }">
                                                    <b-form-group>
                                                        <b-input-group v-on:click="togglePopover">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="CalendarIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input :value="dateToString(inputValue)" @keypress.prevent />
                                                        </b-input-group>
                                                    </b-form-group>
                                                </template>
                                            </v-date-picker>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'created_unixtime'">
                                    {{ moment.unix(props.row[props.column.field]).utc().format("YYYY-MM-DD HH:mm:ss") }}
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";

import BigNumber from "bignumber.js";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
        VDatePicker,
    },

    methods: {
        dateToString(inputValue) {
            return inputValue.start + " - " + inputValue.end;
        },

        clearQueryCondition() {
            this.queryCondition = {
                fromUserId: null,
                toUserId: null,
                creditName: null,
                tag: "peer:airdrop",
            };
            this.dateRange = {
                start: new Date(new Date().valueOf() - 3600 * 24 * 29 * 1000),
                end: new Date(),
            };
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.queryModifyRecord();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.queryModifyRecord();
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.queryModifyRecord();
        },

        async queryModifyRecord() {
            let userId = this.queryCondition.userId ? parseInt(this.queryCondition.userId) : null;
            let creditType = this.queryCondition.creditName;
            let tag = this.queryCondition.tag;
            let startDate = moment(this.dateRange.start).utc().format("YYYY-MM-DD");
            let endDate = moment(this.dateRange.end).utc().format("YYYY-MM-DD");

            // console.log(userId)
            // console.log(startDate,endDate)

            this.table_show_overlay = true;
            // queryMesonRewardExtraChangeRecord(id, UserId, creditType, tag, startDate, endDate, limit, offset, token)
            let resp = await this.$api.user.queryMesonRewardExtraChangeRecord(
                null,
                userId,
                creditType,
                tag,
                startDate,
                endDate,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.data;
            this.totalRows = resp.result.total_count;
        },

        async addNewModify() {
            let userId = parseInt(this.newModify.userId);
            if (isNaN(userId) || userId <= 0) {
                this.$bvToast.toast("user error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let amount = parseInt(this.newModify.amount);
            if (isNaN(amount) || amount===0) {
                this.$bvToast.toast("amount error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let creditType = this.newModify.creditName;
            if (creditType==""){
                this.$bvToast.toast("creditType error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let tag = this.newModify.tag
            if (tag==""){
                this.$bvToast.toast("tag error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            let log = this.newModify.log
            if (log==""){
                this.$bvToast.toast("log error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.add_airdrop_show_overlay = true;
            // adminModifyMesonReward(userId, amount, creditType, allow_negative, tag, log, token)
            let resp = await this.$api.user.adminModifyMesonReward(userId, amount+'', creditType,true,tag,log, this.$store.state.auth.my_web_token);
            this.add_airdrop_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("success", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.newModify= {
                userId: 0,
                amount: "",
                creditName: "MESON_REWARD_TEST",
                tag:"",
                log:"",
            },

            this.queryModifyRecord();
        },
    },

    mounted() {
        this.queryModifyRecord();
    },
    data() {
        return {
            moment: moment,

            //for table
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Date",
                    field: "created_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User id",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Credit Name",
                    field: "credit_type",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Amount",
                    field: "amount",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Tag",
                    field: "tag",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Log",
                    field: "log",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                
                // {
                //     label: "Action",
                //     field: "action1",
                //     tdClass: "text-center",
                //     thClass: "text-center",
                // },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            table_show_overlay: false,
            add_airdrop_show_overlay: false,

            queryCondition: {
                userId: null,
                creditType: null,
                tag: null,
            },
            tagOptions: [
                { text: "airdrop", value: "airdrop" },
                { text: "claim", value: "claim" },
                { text: "punishment", value: "punishment" },
                { text: "meson25_transfer", value: "meson25_transfer" },
                { text: "msntt_transfer", value: "msntt_transfer" },
            ],

            creditNameOptions: [
                { text: "MESON_REWARD_TEST", value: "MESON_REWARD_TEST" },
                { text: "MESON_REWARD_MAIN", value: "MESON_REWARD_MAIN" },
            ],

            newModify: {
                userId: 0,
                amount: "",
                creditName: "MESON_REWARD_TEST",
                tag:"",
                log:"",
            },

            dateRange: {
                start: new Date(new Date().valueOf() - 3600 * 24 * 29 * 1000),
                end: new Date(),
            },
            modelConfig: {
                start: {
                    timeAdjust: "00:00:00",
                },
                end: {
                    timeAdjust: "00:00:00",
                },
            },
        };
    },
};
</script>
